import { useSessionStorage, sessionStorage } from 'react-storage';
var RECENT_STRUCTURE_SEARCHES_STORAGE_KEY = 'recentStructures';
export var MAX_RECENT_SEARCHES = 8;
export function useRecentStructureSearches() {
  var recentStructures = useSessionStorage(RECENT_STRUCTURE_SEARCHES_STORAGE_KEY, []);
  return Array.isArray(recentStructures) ? recentStructures : [];
}
export function addRecentStructureSearch(structureQuery) {
  var recentStructures = sessionStorage.getItem(RECENT_STRUCTURE_SEARCHES_STORAGE_KEY);

  if (!Array.isArray(recentStructures)) {
    recentStructures = [];
  } else if (recentStructures.length >= MAX_RECENT_SEARCHES) {
    recentStructures.shift();
  }

  recentStructures.push(structureQuery);
  return sessionStorage.setItem(RECENT_STRUCTURE_SEARCHES_STORAGE_KEY, recentStructures);
}
export function mostRecentStructureSearch(searches) {
  return searches[searches.length - 1];
}